export const breackpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const colors = {
  customBeige: "#c9b09a",
  customBgc: "#dbd0be",
  customWhite: "#f4eee3",
  customGrey: "#84735e",
  customBrown: "#885a3a",
  customLightBrown: "#a77d54",
  customActiveBgc: "#55555550",
};

export const textContent = {
  heading: "Авторські екскурсії Римом та околицями",
  main: "Рим - це живий музей під відкритим небом. Він захопить вас з першого погляду на Колізей і Пантеон; заінтригує рештками римських форумів та секретними двориками; вразить красою площ, церков, фонтанів, терас й зачарує смаком карбонари, піци Маргарити, джелато й тірамісу. \nА ще - це центр всесвітнього шопінгу, мистецьких подій та італійського «солодкого життя»! \nРим не залишить вас байдужим, якщо знати куди йти, що дивитись та дегустувати. Саме в цьому я хочу бути вам корисною. \nАдже всі дороги ведуть в Рим.",
  about:
    "Зі студентства вивчила італійську мову заради її краси, почала відвідувати Італію з 2008 року, а в 2021 переїхала з Києва в Тіволі через пристрасного італійця. \nУ Римі закінчила курси гідів Mirabilia Urbis, у Папському Григоріанському Університеті відвідувала курси з «Архітектури» та «Мистецтва». \nУ 2024 отримала ліцензію тур лідера (супровід туристів). \nБезтямно закохана у Рим та Тіволі й хочу закохати вас в них. \nПлануєте поїхати в Рим - пишить, з радістю стану вашим провідником у античний, гастрономічний або шопінговий світ Вічного міста. \nНаша прогулянка - це легкий, веселий та доброзичливий формат. \nЩоб замовити екскурсію пишіть  у WhatsApp чи в Instagram @olha.in.italy. \nВсі екскурсії індивідуальні, до 3-х осіб.",
};

export const tours = [
  {
    id: "c91f92cj",
    title: "Оглядова екскурсія «Чао, Рома»",
    description:
      "Вперше в Римі й не знаєте з чого почати знайомство з містом? Ця екскурсія саме для вас. За три години я познайомлю вас з основними пам’ятками Риму, з історією його заснування та головними подіями. Ви відкриєте не лише відомі пам’ятки, а й чарівні куточки, про які відають лише місцеві. А ще з радістю покажу, де випити найсмачнішу каву, з’їсти типову римську карбонару та де поласувати найсмачнішим джелато. Зануртеся у світ античних руїн, величних храмів, барокових фонтанів й символічних місць Риму",
    image: "/images/pexels-julius-silver-240301-753639.jpg",
    plan: [
      "Колізей (ззовні) ",
      " Арка Костянтина ",
      " Імператорські та римський форуми ",
      " Площа Венеції ",
      " Фонтан Треві ",
      " Іспанські сходи ",
      " Пантеон ",
      " Площа Навона",
    ],
    costs: [{ time: "3 год", price: "150 € " }],
  },
  {
    id: "77yo7nw5",
    title: "Римські канікули - прогулянка на день",
    description:
      "У вас лише один день в Римі, й ви не бажаєте пропустити найцікавіше - то для вас такий формат екскурсії підійде найкраще. 6 годин пролетить непомітно, адже краса римських вулиць, двориків та пам’яток компенсує всю втому. Обов’язкова зупинка на обід в одній з колоритних місцевих тратторій на піцу чи пасту.",
    image: "/images/pexels-luis-nunez-24843-105987.jpg",
    plan: [
      "Колізей (ззовні)",
      "Арка Костянтина",
      "Імператорські та римський форуми ",
      "Площа Венеції ",
      "Театр Марцелла",
      "Форум Боаріо",
      "Острів Тіберіна",
      "Портик Октавії",
      "Ларго Арджентіна",
      "Кампо дей Фьорі ",
      "Площа Навона ",
      "Пантеон (ззовні) ",
      "Фонтан Треві ",
      "Іспанські сходи ",
      "Народна Площа",
    ],
    costs: [{ time: "6 год", price: "300 € + обід" }],
  },
  {
    id: "t5vyg761",
    title: "Тіволі та його вілли - д’Есте й Адріана",
    description:
      "Містечко Тіволі старше за Рим майже на 500 років й найантичніше у всьому регіоні Лаціо. Воно зберігає історію гордих тібуртинців, які воювали з римськими імператорами та єпископами. Місто подарувало Риму три акведуки, на його теренах  - термальні джерела, травертинові шахти, унікальний сорт винограду - Піцутелло. Ми побачимо з вами рестки храмів Вести та Сібілли, датовані  I ст. до н.е., побуваємо у Середньовічному кварталі, відвідаємо римський форум та панорамний майданчик. Візит на віллу д’Есте - Всесвітня спадщина Юнеско  - це прогулянка серед розкішних садів та фонтанів епохи Відродження, 500-річних платанів та мальовничих залів палацу. За бажанням - обід в одному з найантичніших ресторанів міста з неперевершеними видами, а також візит на віллу Адріана - величезна площа з рестками палаців, терм, храмів, театрів - 2 ст. н. е.Ви можете дістатись до Тіволі самостійно, або ж замовити транфер.",
    image: "/images/pexels-gottapics-13816327.jpg",
    costs: [
      {
        time: "3 год (Тіволі та вілла д’Есте)",
        price: "150 € + трансфер та обід (за бажанням)",
      },
      {
        time: "6 год (Тіволі та вілла д’Есте, вілла Адріана)",
        price: "300 € + трансфер та обід (за бажанням)",
      },
    ],
    plan: "",
  },
  {
    id: "ecs7ospx",
    title: "Фуд-тур: Італія на смак",
    description:
      "Під час нашої гастрономічної прогулянки по Кампо дей Фьорі ми дізнаємось про знамениті страви римської кухні, продегустуємо піцу в античній пекарні, зробимо зупинку в м’ясній лавці, де спробуємо найсмачніші пармезан та прошуто, зайдемо в атмосферну енотеку, а також посмакуємо артішоками по-юдейські й по-римські в Єврейському гетто. А на десерт - пиріг з рікотою, тірамісу чи джелато.",
    image: "/images/pexels-unkdevil-19130186.webp",
    costs: [{ time: "2 год", price: "120 € + дегустації (від 35 євро/особа)" }],
    plan: "",
  },
  {
    id: "0apqw30k",
    title: "Арт-прогулянка «Рим геніїв та шедеврів»",
    description:
      "Рим був і залишається місцем, де генії всіх епох знаходили натхнення, творили й залишали спадщину, яка захоплює та надихає нас і сьогодні. Під час прогулянки ми знайомимся з шедеврами Мікеланджело, Рафаеля, Караваджо, Берніні, Бороміні, Канова та ін.геніїв. Побачимо будинки, де жили й творили митці, дізнаємось про найдраматичніші моменти їхнього життя та спробуємо відкрити таємницю їхнього натхнення. Маршрут пролягає від Колізею до Іспанських сходів через дворики, палаци, церкви, провулки Риму.",
    image: "/images/pexels-aleksandra-zmuda-9516327-13829408.webp",
    costs: [{ time: "3 год", price: "150 " }],
    plan: "",
  },
];
