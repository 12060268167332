export const Reviews = () => {
  return (
    <div id="reviews" className="px-4 relative md:mb-2">
      <h3 className="font-h text-customBrown italic font-bold mb-4 text-3xl md:text-5xl md:py-6 md:text-center [text-shadow:_0.5px_0.5px_1px_#84735e,_-0.5px_-0.5px_0.5px_#f4eee3]">
        La gloria
      </h3>
      <div className="w-full h-[50vh] flex justify-center items-center text-xl">
        🐲 Here be dragons 🐉
      </div>
    </div>
  );
};
